<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <!-- The div with css causes the progress circle to appear over the brand logo, so using v-progress-circular to make it show up under the brand logo (which is in the layout) -->
                    <!-- <div class="app-splash-loader"></div> -->
                    <v-progress-circular indeterminate color="grey lighten-1" size="36" width="4" class="mt-6"></v-progress-circular>
                </v-row>
            </template>

            <!-- TODO: make this a nice "user card" with avatar (customized photo if we have it, or generic user icon), display name, and email address ; like login page, but without the 'switch account' button since setup is for a specific user -->
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1> -->
                    <!-- <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <font-awesome-icon :icon="['fas', 'user']"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ displayName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list> -->
                    <v-simple-table dense class="mt-4">
                        <template #default>
                            <tbody>
                                <tr>
                                    <td style="width: 32px;">
                                        <font-awesome-icon :icon="['fas', 'user']" size="2x"/>
                                    </td>
                                    <td>
                                        <p class="text-body-1 mb-0">{{ displayName }}</p>
                                        <p class="text-caption mb-0">{{ email }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <template v-if="isViewReady">
                <v-row justify="center" class="py-5">
                    <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                        <v-card elevation="4" class="pa-0" v-if="!isError">
                            <v-app-bar :style="cardTitleBarStyle" flat>
                                <v-toolbar-title :style="cardTitleBarTextStyle">Single Sign-On</v-toolbar-title>
                                <v-progress-linear
                                    :active="isLoading"
                                    :indeterminate="isLoading"
                                    absolute
                                    bottom
                                    :color="accentColor"
                                ></v-progress-linear>
                            </v-app-bar>
                            <v-card-text>
                                <p>Where do you want to go?</p>
                                <!-- <p v-for="app in appList" :key="app.id">
                                    {{ app.display_name }}
                                </p> -->
                            </v-card-text>
                            <v-row no-gutters class="px-2 pb-2">
                                <v-card v-for="app in appList" :key="app.id" max-width="200" hover @click="continueToApp(app.id)" class="mx-2 my-2">
                                    <v-card-text class="text-h6">{{ app.display_name }}</v-card-text>
                                    <!-- {{ app.display_name }} / {{ app.brandprofile }} -->
                                    <!-- {{ app.display_name }} -->
                                </v-card>
                            </v-row>
                        </v-card>
                        <template v-if="requestError">
                            <v-alert type="error">
                                Bad request
                            </v-alert>
                            <!-- TODO: link to return to login page for this realm? or to client app for this realm? -->
                        </template>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>
<style>
.v-data-table {
    background-color: #efefef !important; /* hide data table white background by matching page background */
}
</style>
<script>
import { mapState, mapGetters } from 'vuex';

/*
This activity requires an authenticated user and allows the user to continue
to a registered realm application.
*/

export default {
    components: {
    },
    data: () => ({
        isViewReady: false,

        // alias: null, // user's login username; not editable in this activity
        // email: null, // user's email address; not editable in this activity
        // displayName: null, // user's display name; not editable in this activity

        appList: null,

        requestError: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        displayName() {
            return this.user?.display_name;
        },
        email() {
            return this.user?.email;
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isError() {
            return this.requestError;
        },
    },
    watch: {
        focus() {
            this.searchApp(this.$route.query.sso_app_tag);
        },
    },
    methods: {
        async init() {
            console.log('SsoActivity.vue: init');
            // sso_app_tag is optional; if provided we look for matching apps, if not provided
            // we look for all apps
            await this.searchApp(this.$route.query.sso_app_tag);
            if (this.$route.query.sso_app_tag && this.appList.length === 1) {
                await this.continueToApp(this.appList[0].id);
                return;
            }
            this.isViewReady = true;
        },
        async searchApp(tag) {
            try {
                this.$store.commit('loading', { searchApp: true });
                // load defaults from server
                const result = await this.$client.realm(this.$route.params.realm).applink.search({ tag });
                if (Array.isArray(result.list)) {
                    this.appList = result.list;
                }
            } catch (err) {
                console.error('searchApp failed', err);
                this.appList = [];
            } finally {
                this.$store.commit('loading', { searchApp: false });
            }
        },
        // TODO: need to do this?
        /*
        async prepareView(setupRequest) {
            const {
                intent,
                setup_request_id: setupRequestId,
                required_authn_mechanism_list: requiredAuthnMechanismList,
                optional_authn_mechanism_list: optionalAuthnMechanismList,
                activated_authn_mechanism_list: activatedAuthnMechanismList,
                preferred_authn_mechanism_list: preferredAuthnMechanismList,
                remaining_required_authn_mechanism_list: remainingRequiredAuthnMechanismList,
                remaining_optional_authn_mechanism_list: remainingOptionalAuthnMechanismList,
                password_reset_id: passwordResetToken,
                alias,
                // require_alias: requireAlias, // must be `true` for a setup intent
                email,
                display_name: displayName,
                redirect,
                // next,
                // error,
                // authenticated,
            } = setupRequest;

            if (intent) {
                this.intent = intent; // should be 'setup' for this activity
            }
            if (setupRequestId) {
                this.setupRequestId = setupRequestId;
            }
            if (passwordResetToken) {
                this.passwordResetToken = passwordResetToken;
            }
            if (alias) {
                this.alias = alias; // username, for realms that identify users this way instead of email address
            }
            if (email) {
                this.email = email;
            }
            if (displayName) {
                this.displayName = displayName;
            }
            this.requiredAuthnMechanismList = requiredAuthnMechanismList ?? [];
            this.optionalAuthnMechanismList = optionalAuthnMechanismList ?? [];
            this.activatedAuthnMechanismList = activatedAuthnMechanismList ?? [];
            this.preferredAuthnMechanismList = preferredAuthnMechanismList ?? [];
            this.remainingRequiredAuthnMechanismList = remainingRequiredAuthnMechanismList ?? [];
            this.remainingOptionalAuthnMechanismList = remainingOptionalAuthnMechanismList ?? [];

            if (redirect) {
                console.log(`setupWithToken: redirect ${redirect}`);
                window.location.href = redirect;
                return;
            }
            // if (next) {
            //     console.log(`setupWithToken: next route ${JSON.stringify(next)}`);
            //     this.$router.replace(next);
            //     return;
            // }
            await this.loadAuthenticationPolicy();
        },
        */
        async continueToApp(appId) {
            try {
                this.$store.commit('loading', { continueToApp: true });
                const ssoRequestId = this.$route.query.sso_request_id; // may be null or empty; must point to a valid sso login request; if it doesn't, it will be ignored
                const response = await this.$client.realm(this.$route.params.realm).applink.redirect({ id: appId, sso_request_id: ssoRequestId });
                console.log(`continueToApp response ${JSON.stringify(response)}`);
                const { redirect } = response;
                if (redirect) {
                    if (typeof window.location.push === 'function') {
                        window.location.push(redirect);
                    } else {
                        // TODO: also show the link on the page for user to click
                        window.location.href = redirect;
                    }
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to continue to sso app', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'An unexpected error occurred', message: 'Please contact customer support for assistance' });
                this.isViewReady = true; // since user is staying on this page, we need to ensure the UI is visible with the error message
            } finally {
                this.$store.commit('loading', { continueToApp: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
